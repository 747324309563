
import { mapState, mapGetters } from 'vuex'
import finance from '~/mixins/finance/index'
export default {
    name: 'Default',
    mixins: [finance],
    middleware: ['auth', 'permissions'],
    data() {
        return {
            drawer: false,
            depositNotifications: [],
            withdrawalNotifications: [],
            rightDrawer: false,
            iconBarsDrawer: false,
            loadingLanguage: false,
            items: [],
            header: null,
            windowWidth: 801,
            hostname: '',
        }
    },
    async fetch() {
        // Since backend API need the currency header for the all request,
        // This API fire is for fetching the currency in case the user doesn't have the default currency during login time
        // This API will get fired only when the currency cookie is not getting store
        if (!this.$cookie.get('currency')) {
            await this.$store.dispatch('master/baseCurrency')
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: (() => {
                        const filePath = this.header?.file_path
                        const localePath = filePath?.[this.systemLocale]
                        const defaultPath = filePath?.['en-US']
                        const bucketUrl = `${this.$config.BUCKET}/assets/cms`

                        if (localePath) {
                            return `${bucketUrl}/logos/${localePath}`
                        } else if (defaultPath) {
                            return `${bucketUrl}/${defaultPath}`
                        } else {
                            return '/csl.png'
                        }
                    })(),
                },
            ],
        }
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        title() {
            return this.tl('controlPanel')
        },
        ...mapState({
            // languages: (state) => state.frontend.languages,
            userPermissions: (state) => state.settings.userPermissions,
            logo: (state) => state.frontend.logo,
            menubar: (state) => state.settings.menubar,
            operatorInfo: (state) => state.master.operatorInfo,
            newWithdrawalRequest: (state) =>
                state.settings.newWithdrawalRequest,
            globalDefaultCurrency: (state) =>
                state.master.globalDefaultCurrency,
            newDepositList: (state) => state.finance.newDepositsForNotification,
            newWithdrawalList: (state) =>
                state.finance.newWithdrawalsForNotification,
            baseCurrency: (state) => state.master.baseCurrency,
        }),
        ...mapGetters({
            sideNavCurrency: 'settings/sideNavCurrency',
        }),
        userType() {
            return this.$cookie.get('user')?.user_type
        },
        hideToolbarIcon() {
            return this.windowWidth <= 750
        },
        getOperatorType() {
            return this.$cookie.get('user')?.is_main_operator
        },
        // hide drawer tabs,
        // to hide to drawer by drawer tab's codes
        // if the code are exists here, don't display
        parentBlocksTab() {
            // If the user is operator level 1, don't block any tab
            if (this.getOperatorType) return []
            return ['operator']
        },
        childrenBlocksTab() {
            // If the user is operator level 1, don't block any tab
            if (this.getOperatorType) return []
            return ['company_report']
        },
        // To disable currency selection requirements
        // 1. Disable if the website is for international and the user is not super admin
        disabledCurrencySelection() {
            // For Lao Clients, the selection is always enabled
            return false
        },
    },
    watch: {
        $route(to) {
            if (to.fullPath) {
                this.$cookie.set('routePath', to?.fullPath)
            }
        },
        menubar(value) {
            const data = JSON.parse(JSON.stringify(value))
            const filteredArray = this.checkLaoInter(data)
            this.items = filteredArray
        },
        async userPermissions(value) {
            if (this.userType !== 1) {
                // getting new permissions update tabs
                const items = await this.$store.dispatch(
                    'settings/getItemsWithDisplayStatus'
                )
                const filteredArray = this.checkLaoInter(items)
                this.items = filteredArray
            }
        },
        logo(array) {
            array.forEach((object) => {
                if (object.type === 1) {
                    this.header = JSON.parse(JSON.stringify(object))
                }
            })
        },
        baseCurrency(value) {
            if (value) {
                const defaultCurrency =
                    this.$store.state.master.globalDefaultCurrency
                const checkIfTheDefaultCurrencyExist = value.find(
                    (el) => el.code === defaultCurrency
                )
                // if the default currency doesn't exist in Active Currency List
                // set the default currency to be the first list in currency
                if (!checkIfTheDefaultCurrencyExist) {
                    if (value.length > 0) {
                        const code = value[0].code
                        this.$store.commit('master/globalDefaultCurrency', code)
                        this.$cookie.set('currency', code)
                    }
                } else {
                    this.$cookie.set(
                        'currency',
                        checkIfTheDefaultCurrencyExist.code
                    )
                }
            }
        },
        newDepositList(value) {
            if (value?.data && value?.data?.length > 0) {
                const notificationArr = value.data.map((el) => {
                    return {
                        color: 'success',
                        icon: 'mdi-arrow-down',
                        link: '/finance/deposits', // + msg.deposit_id,
                        long: '1s',
                        view: false,
                        deposit_id: el.id,
                        player_id: el.player_id,
                        player_name: el.name,
                        agent_uuid: el.agent_username,
                        amount: el.deposit_amount,
                        currency: el.currency,
                        status: el.deposit_status,
                        transfer_type: el.deposit_type,
                        notify_date: el.created_at,
                    }
                })
                this.depositNotifications = []
                this.depositNotifications = [...notificationArr]
            } else {
                this.depositNotifications = []
            }
        },
        newWithdrawalList(value) {
            if (value?.data && value?.data?.length > 0) {
                const notificationArr = value.data.map((el) => {
                    return {
                        color: 'error',
                        icon: 'mdi-arrow-up',
                        link: '/finance/withdrawals', // + msg.deposit_id,
                        long: '1s',
                        view: false,
                        deposit_id: el.id,
                        player_id: el.player_id,
                        player_name: el.name,
                        agent_uuid: el.agent_username,
                        amount: el.withdrawal_amount,
                        currency: el.from_c,
                        status: el.withdrawal_status,
                        transfer_type: el.withdrawal_type,
                        notify_date: el.created_at,
                    }
                })
                this.withdrawalNotifications = []
                this.withdrawalNotifications = [...notificationArr]
            } else {
                this.withdrawalNotifications = []
            }
        },
    },
    created() {
        this.$store.commit(
            'master/globalDefaultCurrency',
            this.$cookie.get('currency')
        )
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
        this.$vuetify.breakpoint.xsOnly = false // Apply to fixed not match server rendering error on reload page for new table footer design
    },
    async mounted() {
        // Fetch languages
        await this.getLanguageStringForTranslation()

        this.hostname = window.location.hostname
        // uncomment the following line to see currency response
        if (this.baseCurrency && Object.keys(this.baseCurrency).length === 0) {
            await this.$store.dispatch('master/baseCurrency')
        }
        // ? Get user permissions
        if (this.userType === 2) {
            await this.$store.dispatch('settings/userPermissions')
        }

        // Finance Notification
        // To check many deposits are in pending, those params in the below request is the default one in finance/deposit tab
        this.fetchDepositForNotificationList()
        this.fetchWithdrawalForNotificationList()

        if (this.userType === 1) {
            const value = this.$store.state.settings.menubarTab
            // Filter international & lao tab
            const data = JSON.parse(JSON.stringify(value))
            const filteredArray = this.checkLaoInter(data)
            this.items = filteredArray
        }
        await this.$store.dispatch('settings/redirectToHasPermissionRoute')
        // Check if currency is selected
        if (this.$cookie.get('currency')) {
            const filter = this.baseCurrency.find(
                (el) => el.code === this.$cookie.get('currency')
            )
            if (filter) {
                this.$store.commit(
                    'master/globalDefaultCurrency',
                    this.$cookie.get('currency')
                )
                // Set currency type for check if fiat or crypto
                this.$cookie.set('currency_type', filter.type)
            }
        }
        // ? get languages translation
        this.loadingLanguage = true
        this.switchLanguage(null)
        // await this.$store.dispatch('frontend/languages')
        await this.$store.dispatch('master/languages')
        this.loadingLanguage = false

        // Socket initiating
        this.socket = await this.$nuxtSocket({
            reconnection: true,
            upgrade: false,
            auth: {
                Authorization: `Bearer ${this.$cookie.get('token')}`,
            },
            transports: ['websocket'],
        })
        this.socket.on('disconnect', (e) => {
            // for  bank testing
            this.$store.commit('external-data/setSocket', 'disconnect')
            setTimeout(() => {
                this.socket.connect()
            }, 5000)
        })
        let reconnectCount = 0
        this.socket.on('connect_error', (e) => {
            if (reconnectCount < 3) {
                setTimeout(() => {
                    this.socket.connect()
                }, 5000)
                reconnectCount++
            }
        })
        // Subscribe Store, Socket Only
        this.$store.subscribeAction((mutation, state) => {
            // Confirm Deposit
            if (mutation.type === 'sockets/confirmDeposit') {
                const msg = mutation.payload
                // Selected currency (On Navar) and the new deposit currency
                const userSelectedCurrency =
                    this.globalDefaultCurrency?.toLowerCase()
                const depositCurrency = msg?.currency?.toLowerCase()

                // To check if the selected currency is matched to the new deposit, then display the notification
                if (userSelectedCurrency === depositCurrency) {
                    this.playNotification(this.tl('newDepositRequest'))
                    this.fetchDepositForNotificationList()
                }
            } else if (mutation.type === 'sockets/createWithdrawal') {
                const msg = mutation.payload
                // Selected currency (On Navar) and the new deposit currency
                const userSelectedCurrency =
                    this.globalDefaultCurrency?.toLowerCase()
                const depositCurrency = msg?.currency?.toLowerCase()

                if (userSelectedCurrency === depositCurrency) {
                    this.playNotification(this.tl('newWithdrawalRequest'))
                    this.fetchWithdrawalForNotificationList()
                }
            } else if (
                mutation.type === 'sockets/lockDeposit' ||
                mutation.type === 'sockets/unlockDeposit' ||
                mutation.type === 'sockets/revokeDepositByAutoTopup'
            ) {
                this.fetchDepositForNotificationList()
            } else if (
                mutation.type === 'sockets/declinedWithdrawalByPlayer' ||
                mutation.type === 'sockets/lockWithdrawal' ||
                mutation.type === 'sockets/unlockWithdrawal'
            ) {
                this.fetchWithdrawalForNotificationList()
            }
        })
    },
    methods: {
        // move from global.js
        checkUserPermission(type, code = null) {
            // type: create, delete, update, view
            const permissionsByCurrentPermissionCode =
                this.userPermissions.find(
                    (el) => el.code === (code || this.currentPermissionCode)
                )
            if (!permissionsByCurrentPermissionCode) {
                return this.$cookie.get('user')?.user_type === 1
            }

            if (type === 'view') {
                return permissionsByCurrentPermissionCode.can_view
            }
            if (type === 'create') {
                return permissionsByCurrentPermissionCode.can_create
            }
            if (type === 'delete') {
                return permissionsByCurrentPermissionCode.can_delete
            }
            if (type === 'update') {
                return permissionsByCurrentPermissionCode.can_update
            }
        },
        connectAgain() {
            this.socket.emit('message', 'clicked-again')
        },
        checkLaoInter(inputItem) {
            // To manually block some tabs
            // For example, block Bank Management for USDT currency
            if (this.isCryptoCurrency) {
                // Hide Bank Management Tab
                inputItem.forEach((mainTab) => {
                    if (
                        mainTab.code === 'finance' &&
                        mainTab?.children?.length
                    ) {
                        mainTab.children.forEach((mainTabChild) => {
                            // Set this tab to be hidden
                            if (mainTabChild.code === 'bank_management') {
                                mainTabChild.display = false
                            }
                        })
                    }
                })
            }

            const data = inputItem

            const filteredArray = data
                .map((item) => {
                    if (item.children) {
                        item.children = item.children.filter(
                            (child) => child.wl_type !== 1
                        )
                    }
                    return item
                })
                .filter((item) => !item.children || item.children.length > 0)
            return filteredArray
        },
        switchLanguage(code) {
            if (code) {
                this.$cookie.set('locale', code)
                window.location.reload()
            } else {
                this.$cookie.set(
                    'locale',
                    this.$cookie.get('locale') ?? 'en-US'
                )
                if (!this.$cookie.get('locale')) {
                    window.location.reload()
                }
            }
        },
        checkPermissions(code, type) {
            if (this.userType === 1) return true

            const getPermission = this.userPermissions.find(
                (el) => el.code === code
            )
            if (!getPermission) return false
            const {
                can_create: canCreate,
                can_delete: canDelete,
                can_update: canUpdate,
                can_view: canView,
            } = getPermission
            if (type === 'view') return canView
            if (type === 'update') return canUpdate
            if (type === 'delete') return canDelete
            if (type === 'create') return canCreate
            return false
        },
        async getLanguageStringForTranslation() {
            const cookieLocale = this.$cookie.get('locale')
            await this.$store.dispatch(
                'master/translationStrings',
                cookieLocale
            )
        },
        onChangeCurrency(e) {
            // To change currency_type
            const findCurrency = this.baseCurrency.find((el) => el.code === e)
            if (findCurrency) {
                this.$cookie.set('currency_type', findCurrency.type)
            }
            this.$store.commit('master/globalDefaultCurrency', e)
            this.$cookie.set('currency', e)
            this.$router.go(0)
        },
        playNotification(toastText) {
            this.$toast.info(toastText)
            if (this.$cookie.get('sound')) {
                const audio = new Audio('/sounds/notification.mp3')
                audio.play()
                setTimeout(() => {
                    audio.play()
                }, 3000)
            }
        },
        removeItem(index) {
            this.$delete(this.depositNotifications, index)
        },
        toggleDropdown(toggle) {
            if (toggle) {
                for (const notify of this.depositNotifications) {
                    notify.long = this.calculateTime(notify.notify_date)
                }
            }
        },
        calculateTime(dateTime) {
            const duration = this.$dayjs.duration(
                this.$dayjs().diff(this.$dayjs(dateTime))
            )
            const year = Math.floor(duration.asYears())
            if (year) return year + 'Y'
            const month = Math.floor(duration.asMonths())
            if (month) return month + 'M'
            const week = Math.floor(duration.asWeeks())
            if (week) return week + 'W'
            const day = Math.floor(duration.asDays())
            if (day) return day + 'D'
            const hour = Math.floor(duration.asHours())
            if (hour) return hour + 'H'
            const minute = Math.floor(duration.asMinutes())
            if (minute) return minute + 'm'
            const second = Math.floor(duration.asSeconds())
            return second + 's'
        },
        toolbarSizeChange() {
            if (process.client) {
                this.windowWidth = window.innerWidth
            }
        },
        savePermissionCode(code) {
            // Closing drawer on clicking Tab is just to work on production only
            if (!this.$config.DEVELOPMENT && !this.$cookie.get('drawer')) {
                this.drawer = false
            }
            if (!code) return null
            this.$store.dispatch('settings/permissionCode', code)
            this.$cookie.set('permissionCode', code)
        },
        disabledTabWhichIsNotDevelopment() {
            const enabledHostNames = ['localhost', 'office.cslvault.com']
            return enabledHostNames.includes(this.hostname)
        },
        isOnlyDevelopmentServer() {
            const enabledHostNames = ['localhost', 'office.cslvault.com']
            return enabledHostNames.includes(this.hostname)
        },
        fetchDepositForNotificationList() {
            const depositPermissionCode = 'deposit'
            if (
                this.isSuperAdmin() ||
                this.checkUserPermission('view', depositPermissionCode)
            ) {
                this.$store.dispatch('finance/newDeposits', {
                    sort: 'created_at|desc',
                    limit: 10,
                    page: 1,
                    start_date:
                        this.$dayjs().format('YYYY-MM-DD') + ' 00:00:00',
                    end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
                    search_type: 1, // 1  createdTime, 2 updatedTime
                    currency: this.globalDefaultCurrency,
                    for_notifications: true,
                })
            }
        },
        fetchWithdrawalForNotificationList() {
            const withdrawalPermissionCode = 'withdrawal'
            if (
                this.isSuperAdmin() ||
                this.checkUserPermission('view', withdrawalPermissionCode)
            ) {
                this.$store.dispatch('finance/newWithdrawals', {
                    sort: 'created_at|desc',
                    limit: 10,
                    page: 1,
                    start_date:
                        this.$dayjs().format('YYYY-MM-DD') + ' 00:00:00',
                    end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
                    search_type: 1, // 1  createdTime, 2 updatedTime
                    currency: this.globalDefaultCurrency,
                    for_notifications: true,
                })
            }
        },
    },
}
