export default {
    computed: {
        isCryptoCurrency() {
            if (this.$cookie.get('currency_type')) {
                const currencyType = parseFloat(
                    this.$cookie.get('currency_type')
                )
                // 1 is fiat currency, 2 is crypto currency
                return currencyType === 2
            }
            return false
        },
    },
    methods: {
        decimalRule(v, length = 2) {
            if (!v) return
            // Convert number to string
            const numStr = v?.toString()?.replace(/,/g, '')?.toString()
            // Find the position of the decimal point
            const decimalIndex = numStr?.indexOf('.')

            // If there's no decimal point, return false
            if (decimalIndex === -1) {
                return true
            }
            // Check the length of the part after the decimal point
            const decimalPlaces = numStr.length - decimalIndex - 1
            // Return true if exactly one decimal place, otherwise false
            return decimalPlaces >= 1 && decimalPlaces <= length
        },
        exchangeRateDecimalRule(v) {
            if (!v) return
            // Convert number to string
            const numStr = v?.toString()?.replace(/,/g, '')?.toString()
            // Find the position of the decimal point
            const decimalIndex = numStr?.indexOf('.')

            // If there's no decimal point, return false
            if (decimalIndex === -1) {
                return true
            }
            // Check the length of the part after the decimal point
            const decimalPlaces = numStr.length - decimalIndex - 1
            // Return true if exactly one decimal place, otherwise false
            return decimalPlaces >= 1 && decimalPlaces <= 11
        },
        unFormatNumber(number) {
            return number?.toString().replace(/,/g, '')
        },
        truncateDecimalPlaces(number, decimalLength = 11) {
            if (!number) return 0
            const splitDots = number?.toString()?.split('.')

            const formattedNumber = this.$currencyJs(parseInt(number), {
                separator: ',',
                symbol: '',
                precision: 0,
            }).format()
            if (splitDots.length === 2) {
                const decimalPart = splitDots[1].slice(0, decimalLength)
                return `${formattedNumber}.${decimalPart}`
            } else {
                return `${formattedNumber}.00`
            }
        },
    },
}
