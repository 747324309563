
// import base64toblob from 'base64toblob'

export default {
    name: 'ImageUPloadDragDrop',
    props: {
        // eslint-disable-next-line vue/require-prop-types
        image: {
            default: () => {},
        },
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: () => {},
        },
        // ? This is the showing image, after drop or select the image file
        size: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        // ? Width and height are the expected image size, and showing in recommended size
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
        // ? check is used for checking if the image is matched to the width and height
        check: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        jpgOnly: {
            type: Boolean,
            default: false,
        },
        src: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        showTextRecommended: {
            type: Boolean,
            default: true,
        },
        maxUploadSize: {
            type: Number,
            default: 1000,
        },
        isFavicon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dragover: false,
            uploadedFiles: [],
            selectImage: null,
            imageUrl: null,
            fileImage: null,
            imageIsRequired: false,
            validationError: null,
            warningErrorColor: true,
            enableToShowImage: false,
            // compress image
            file: {},
            result: {},
            reader: {},
            scale: 100,
            quality: 95,
            type: null,
            selectedImageData: null,
        }
    },
    computed: {
        rules() {
            return [
                (v) => {
                    if (!this.required) {
                        return true
                    }
                    if (!v) {
                        this.imageIsRequired = true
                        return this.tl('isRequired')
                    } else {
                        return true
                    }
                },
            ]
        },
    },
    watch: {
        value(value) {
            if (!value) {
                this.imageUrl = null
                this.selectImage = null
            }
        },
        src(val) {
            if (!val) return
            if (val?.includes('undefined') || val?.includes('null')) {
                this.enableToShowImage = false
                return
            }
            this.enableToShowImage = true
        },
    },
    mounted() {
        if (this.src) {
            if (this.src.includes('undefined')) {
                this.enableToShowImage = false
                return
            }
            this.enableToShowImage = true
        }
    },
    methods: {
        removeFile() {
            this.selectedImageData = null
            this.uploadedFiles = []
            this.imageUrl = null
            this.selectImage = null
            this.fileImage = null
            this.warningErrorColor = true
            this.$emit('input', '')

            if (this.src) {
                if (window.confirm(this.tl('areYouSureToDelete'))) {
                    this.enableToShowImage = false
                    this.validationError = true
                }
            }
        },
        onDrop(e, type = 'drag') {
            this.dragover = false
            this.warningErrorColor = true
            const fileType =
                type === 'drag' ? e.dataTransfer.files[0].type : e.type

            const size = type === 'drag' ? e.dataTransfer.files[0].size : e.size
            // e.size = 1kb * 1000
            if (size > this.maxUploadSize * 1000) {
                // Max 1MB
                this.$toast.error(
                    `${this.tl('imageSizeShouldBeLessThan')} ${
                        this.maxUploadSize
                    } ${this.tl('kb')}`
                )
                return false
            }

            if (this.isFavicon) {
                if (
                    ![
                        'image/ico',
                        'image/png',
                        'image/vnd.microsoft.icon',
                    ].includes(fileType)
                ) {
                    this.validationError = this.tl('pleaseSelectOnlyPngIco')
                    this.$toast.error(this.tl('acceptOnlyIcoFile'))
                    return false
                }
            }
            if (!this.isFavicon) {
                if (
                    !this.isFavicon && this.jpgOnly
                        ? !['image/jpeg', 'image/jpg', 'image/gif'].includes(
                              fileType
                          )
                        : ![
                              'image/jpeg',
                              'image/png',
                              'image/jpg',
                              'image/gif',
                          ].includes(fileType)
                ) {
                    this.validationError = this.tl(
                        'pleaseSelectImageFileValidationError'
                    )
                    this.$toast.error(this.tl('acceptOnlyJpegFile'))
                    return false
                } else {
                    this.validationError = null
                }
            }

            if (type === 'select') {
                this.selectImage = e
                this.$emit('input', e)
                this.onCheckImageSize(e)
            } else {
                // If there are already uploaded files remove them
                if (this.uploadedFiles.length > 0) this.uploadedFiles = []
                // this.selectImage = e.dataTransfer.files[0]
                this.onChange(e.dataTransfer.files[0])
                // this.$emit('input', e.dataTransfer.files[0])
                // this.onCheckImageSize(e.dataTransfer.files[0])
            }
        },
        previewImage() {
            if (this.selectImage) {
                this.imageUrl = URL.createObjectURL(this.selectImage)
            }
        },
        // to browse image file
        selectFile() {
            this.warningErrorColor = true
            if (!this.enableToShowImage) {
                this.selectImage = null
            }
            if (!this.selectImage) {
                this.$refs.uploader.$refs.input.click()
            }
        },
        fileInputChange(e) {
            if (e) {
                this.onDrop(e, 'select')
            }
        },
        onCheckImageSize(imageFile) {
            this.previewImage()
            if (!this.check) return false

            const reader = new FileReader()
            const imgSize = new Image()
            reader.readAsDataURL(imageFile)

            reader.onload = (evt) => {
                imgSize.onload = () => {
                    if (
                        imgSize.height !== this.height &&
                        imgSize.width !== this.width &&
                        this.check
                    ) {
                        this.validationError = `${this.tl(
                            'expectedImageSizeIs'
                        )} ${this.width} x ${this.height} pixel.`

                        this.warningErrorColor = true
                        this.enableToShowImage = false
                        return true
                    } else {
                        this.validationError = null
                        this.enableToShowImage = true
                        return false
                    }
                }
                // Why 5?
                // Since evt.target.result will have value `data:` incase no image got uploaded
                // Without this if condition, it will throw console.error
                // If you wanna know how it works, remove this condition and check your console while uploading
                if (evt.target.result.length > 5) {
                    imgSize.src = evt.target.result
                }
            }
        },
        async getReQualityImage(obj) {
            // if (obj.compressed.size !== '0 kB') {
            const image = await this.getFiles(obj)
            this.selectImage = image
            this.fileImage = image
            this.onCheckImageSize(image)
            this.$emit('input', image)
            // }
        },
        async getFiles(obj) {
            const file = await fetch(obj.compressed.base64)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], obj.compressed.name, {
                        type: this.type,
                    })
                    return file
                })
            return file
        },
        /**
         * Compress image part
         */
        /*
        When Input File has changed
      	*/
        onChange(e) {
            if (!e) return null
            // e.size = 1kb * 1000
            if (e.size > this.maxUploadSize * 1000) {
                this.$toast.error(
                    `${this.tl('imageSizeShouldBeLess')} ${
                        this.maxUploadSize
                    } ${this.tl('kb')}`
                )
                return false
            }

            const fileType = e.type
            if (this.isFavicon) {
                if (
                    ![
                        'image/ico',
                        'image/png',
                        'image/vnd.microsoft.icon',
                    ].includes(fileType)
                ) {
                    this.validationError = this.tl('pleaseSelectOnlyPngIco')
                    this.$toast.error(this.tl('acceptOnlyIcoFile'))
                    return false
                }
            }
            if (!this.isFavicon) {
                if (
                    this.isFavicon === false &&
                    (this.jpgOnly
                        ? !['image/jpeg', 'image/jpg', 'image/gif'].includes(
                              fileType
                          )
                        : ![
                              'image/jpeg',
                              'image/png',
                              'image/jpg',
                              'image/gif',
                          ].includes(fileType))
                ) {
                    this.validationError = this.tl(
                        'pleaseSelectImageFileValidationError'
                    )
                    this.$toast.error(this.tl('acceptOnlyJpegFile'))
                    return false
                }
            }

            // If There's no file choosen
            const file = e
            if (!file) return false

            // get the file
            this.file = e

            // Validation
            const type = this.file.type
            this.type = type
            const valid = type.includes('image')

            if (!valid)
                // eslint-disable-next-line no-throw-literal
                throw 'File Type Is Not Supported. Upload an image instead'

            // Make new FileReader
            this.reader = new FileReader()

            // Convert the file to base64 text
            this.reader.readAsDataURL(this.file)

            // on reader load somthing...
            this.reader.onload = this.fileOnLoad
            setTimeout(() => {
                this.redraw()
            }, 100)
        },

        /*
        Draw And Compress The Image
        @params {String} imgUrl
		URL: https://www.npmjs.com/package/vue-image-compressor
      */
        async drawImage(imgUrl) {
            if (this.type === 'image/gif') {
                const objToPass = await this.createGifObject(imgUrl, this.file)
                this.imageUrl = imgUrl // Directly use the URL for preview
                this.getReQualityImage(objToPass)
            } else {
                // Proceed with existing image processing for non-GIFs
                const objToPass = await this.createCompressedImageObject(imgUrl)
                this.getReQualityImage(objToPass)
            }
        },
        async createCompressedImageObject(imgUrl) {
            // Example function to create the object needed for getReQualityImage
            const fileName = this.file.name
            const extension = this.type?.split('/')[1]
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            // Create and load image
            const img = new Image()
            img.src = imgUrl

            // Wait for the image to load before processing
            await new Promise((resolve) => (img.onload = resolve))

            // Resize canvas based on scaling
            const scale = this.scale / 100
            canvas.width = img.width * scale
            canvas.height = img.height * scale
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

            // Generate compressed image base64
            const quality = this.quality ? this.quality / 100 : 1
            const base64 = canvas.toDataURL(this.type, quality)

            // Return compressed image object
            return {
                original: this.file,
                compressed: {
                    blob: this.toBlob(base64),
                    base64,
                    name: `${fileName.split('.')[0]}.${extension}`,
                    file: this.buildFile(base64, fileName),
                },
            }
        },

        /*
        Redraw the canvas
      */
        redraw() {
            if (this.result.base64) {
                this.drawImage(this.result.base64)
            }
        },

        /*
        When The File in loaded
      */
        fileOnLoad() {
            // The File
            const { file } = this

            // Make a fileInfo Object
            const fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: this.reader.result,
                file,
            }

            // Push it to the state
            this.result = fileInfo

            // DrawImage
            this.drawImage(this.result.base64)
        },

        // Convert Base64 to Blob
        toBlob(imgUrl) {
            const blob = this.$base64toblob(imgUrl.split(',')[1], this.type)
            const url = window.URL.createObjectURL(blob)
            return url
        },

        // Convert Blob To File
        buildFile(blob, name) {
            return new File([blob], name, { type: this.type })
        },
        createGifObject(imgUrl, file) {
            // Build the objToPass for GIFs
            return {
                original: file,
                compressed: {
                    blob: this.toBlobForGif(imgUrl), // Use the base64 as-is
                    base64: imgUrl,
                    name: file.name,
                    file, // Use the original file
                },
            }
        },
        toBlobForGif(imgUrl) {
            // Convert Base64 to Blob for uniformity with other image types
            const binary = atob(imgUrl.split(',')[1])
            const array = []
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }
            return new Blob([new Uint8Array(array)], { type: this.type })
        },
    },
}
