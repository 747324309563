// State
export const state = () => ({
	loading: false,
	stats: {},
	allSummary: {
		totals: {},
		withdrawal: {},
		top_five_direct_players_deposit: {},
		top_five_associate_players_deposit: {},
		players: {},
		manual_adjustment_positive: {},
		manual_adjustment_negative: {},
		deposit: {},
		bonus: {},
		bannerDeposit: {},
		bannerWithdrawal: {},
		bannerRegistration: {},
		depositCount: {},
		withdrawalCount: {},
		playerCount: {},
		depositAmountCount: {},
		withdrawalAmountCount: {},
		activePlayer: {}
	},
	depositPlayerList: {},
	withdrawalPlayerList: {},
	agentFee: [],
	marketingPrice: [],
	highestDeposit: [],
	highestWithdrawal: [],
	allSummaryLoading: {
		totals: false,
		withdrawal: false,
		top_five_direct_players_deposit: false,
		top_five_associate_players_deposit: false,
		players: false,
		manual_adjustment_positive: false,
		manual_adjustment_negative: false,
		deposit: false,
		bonus: false,
		bannerDeposit: false,
		bannerWithdrawal: false,
		bannerRegistration: false,
		depositCount: false,
		withdrawalCount: false,
		playerCount: false,
		depositAmountCount: false,
		withdrawalAmountCount: false,
		activePlayer: false
	},
	playerWinList: {
		data: []
	},
	playerLostList: {
		data: []
	},
	playerWinListLoading: false,
	playerLoseListLoading: false,
	// cancel token
	statsCancelToken: {}
})

// Actions
export const actions = {
	// * Statistics
	async stats({ commit, state }, data) {
		commit('setStatsCancelToken', { stats: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/dashboard/stats`, {
				params: data,
				cancelToken: state.statsCancelToken.stats.token
			})
			.then((response) => {
				commit('stats', response.data)
				commit('loading', false)
			}).catch(() => {
				commit('loading', false)
			})
	},
	async players({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			players: true,
		})
		commit('setStatsCancelToken', { player: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/players`, { params, cancelToken: state.statsCancelToken.player.token })
			.then((response) => {
				commit('setSummary', {
					players: response.data
				})
				commit('setAllSummaryLoading', {
					players: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					players: false,
				})
			})
	},
	async deposit({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			deposit: true,
		})
		commit('setStatsCancelToken', { player: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/summary-of-deposit`, { params, cancelToken: state.statsCancelToken.player.deposit })
			.then((response) => {
				commit('setSummary', {
					deposit: response.data
				})
				commit('setAllSummaryLoading', {
					deposit: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					deposit: false,
				})
			})
	},
	async internationalDeposit({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			deposit: true,
		})
		commit('setStatsCancelToken', { internationalDeposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-deposit`, { params, cancelToken: state.statsCancelToken.internationalDeposit.token })
			.then((response) => {
				commit('setSummary', {
					deposit: response.data
				})
				commit('setAllSummaryLoading', {
					deposit: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					deposit: false,
				})
			})
	},
	async bannerDeposit({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			bannerDeposit: true,
		})
		commit('setStatsCancelToken', { bannerDeposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-banner-deposit`, { params, cancelToken: state.statsCancelToken.bannerDeposit.token })
			.then((response) => {
				commit('setSummary', {
					bannerDeposit: response.data
				})
				commit('setAllSummaryLoading', {
					bannerDeposit: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					bannerDeposit: false,
				})
			})
	},
	async totals({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			totals: true,
		})
		commit('setStatsCancelToken', { totals: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/totals`, { params, cancelToken: state.statsCancelToken.totals.token })
			.then((response) => {
				commit('setSummary', {
					totals: response.data
				})
				commit('setAllSummaryLoading', {
					totals: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					totals: false,
				})
			})
	},
	async internationalTotals({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			totals: true,
		})
		commit('setStatsCancelToken', { internationalTotals: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/totals`, { params, cancelToken: state.statsCancelToken.internationalTotals.token })
			.then((response) => {
				commit('setSummary', {
					totals: response.data
				})
				commit('setAllSummaryLoading', {
					totals: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					totals: false,
				})
			})
	},
	async withdrawal({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			withdrawal: true,
		})
		commit('setStatsCancelToken', { withdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/summary-of-withdrawal`, { params, cancelToken: state.statsCancelToken.withdrawal.token })
			.then((response) => {
				commit('setSummary', {
					withdrawal: response.data
				})
				commit('setAllSummaryLoading', {
					withdrawal: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					withdrawal: false,
				})
			})
	},
	async internationalWithdrawal({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			withdrawal: true,
		})
		commit('setStatsCancelToken', { internationalWithdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-withdraw`, { params, cancelToken: state.statsCancelToken.internationalWithdrawal.token })
			.then((response) => {
				commit('setSummary', {
					withdrawal: response.data
				})
				commit('setAllSummaryLoading', {
					withdrawal: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					withdrawal: false,
				})
			})
	},
	async bannerWithdrawal({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			bannerWithdrawal: true,
		})
		commit('setStatsCancelToken', { bannerWithdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-banner-withdraw`, { params, cancelToken: state.statsCancelToken.bannerWithdrawal.token })
			.then((response) => {
				commit('setSummary', {
					bannerWithdrawal: response.data
				})
				commit('setAllSummaryLoading', {
					bannerWithdrawal: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					bannerWithdrawal: false,
				})
			})
	},
	async bannerRegistration({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			bannerRegistration: true,
		})
		commit('setStatsCancelToken', { bannerRegistration: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/player-registration-banner-reward`, { params, cancelToken: state.statsCancelToken.bannerRegistration.token })
			.then((response) => {
				commit('setSummary', {
					bannerRegistration: response.data
				})
				commit('setAllSummaryLoading', {
					bannerRegistration: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					bannerRegistration: false,
				})
			})
	},
	async depositCount({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			depositCount: true,
		})
		commit('setStatsCancelToken', { depositCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/deposit-count-player-type-and-promotion`, { params, cancelToken: state.statsCancelToken.depositCount.token })
			.then((response) => {
				commit('setSummary', {
					depositCount: response.data
				})
				commit('setAllSummaryLoading', {
					depositCount: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					depositCount: false,
				})
			})
	},
	async withdrawalCount({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			withdrawalCount: true,
		})
		commit('setStatsCancelToken', { withdrawalCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/withdraw-count-player-type-and-promotion`, { params, cancelToken: state.statsCancelToken.withdrawalCount.token })
			.then((response) => {
				commit('setSummary', {
					withdrawalCount: response.data
				})
				commit('setAllSummaryLoading', {
					withdrawalCount: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					withdrawalCount: false,
				})
			})
	},
	async playerCount({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			playerCount: true,
		})
		commit('setStatsCancelToken', { playerCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/player-registration`, { params, cancelToken: state.statsCancelToken.playerCount.token })
			.then((response) => {
				commit('setSummary', {
					playerCount: response.data
				})
				commit('setAllSummaryLoading', {
					playerCount: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					playerCount: false,
				})
			})
	},
	async depositAmountCount({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			depositAmountCount: true,
		})
		commit('setStatsCancelToken', { depositAmountCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/manual-deposit-adjustment`, { params, cancelToken: state.statsCancelToken.depositAmountCount.token })
			.then((response) => {
				commit('setSummary', {
					depositAmountCount: response.data
				})
				commit('setAllSummaryLoading', {
					depositAmountCount: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					depositAmountCount: false,
				})
			})
	},
	async withdrawalAmountCount({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			withdrawalAmountCount: true,
		})
		commit('setStatsCancelToken', { withdrawalAmountCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/manual-withdraw-adjustment`, { params, cancelToken: state.statsCancelToken.withdrawalAmountCount.token })
			.then((response) => {
				commit('setSummary', {
					withdrawalAmountCount: response.data
				})
				commit('setAllSummaryLoading', {
					withdrawalAmountCount: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					withdrawalAmountCount: false,
				})
			})
	},
	async depositPlayerList({ commit, state }, params) {
		commit('loading', true)
		commit('setStatsCancelToken', { depositPlayerList: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/player-deposit`, { params, cancelToken: state.statsCancelToken.depositPlayerList.token })
			.then((response) => {
				const data = {
					total: response.data.total,
					total_pages: response.data.total_pages,
					data: response.data.data
				}
				if (data?.data?.length < 10) {
					data.total = 10
					for (let i = data.data.length + 1; i <= 10; i++) {
						data.data[i] = { player_id: "-", total_deposit_amount: "-", deposit_count: "-", created_at: "-", source_name: "-" }
					}
				}
				commit('depositPlayerList', data)
				commit('loading', false)
			}).catch(_ => {
				commit('loading', false)
			})
	},
	async withdrawalPlayerList({ commit, state }, params) {
		commit('loading', true)
		commit('setStatsCancelToken', { withdrawalPlayerList: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/player-withdraw`, { params, cancelToken: state.statsCancelToken.withdrawalPlayerList.token })
			.then((response) => {
				const data = {
					total: response.data.total,
					total_pages: response.data.total_pages,
					data: response.data.data
				}
				if (data?.data?.length < 10) {
					data.total = 10
					for (let i = data.data.length + 1; i <= 10; i++) {
						data.data[i] = { player_id: "-", total_withdraw_amount: "-", withdraw_count: "-", created_at: "-" }
					}
				}
				commit('withdrawalPlayerList', data)
				commit('loading', false)
			}).catch(_ => {
				commit('loading', false)
			})
	},
	async agentFee({ commit, state }, params) {
		commit('loading', true)
		commit('setStatsCancelToken', { agentFee: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/agent-fee`, { params, cancelToken: state.statsCancelToken.agentFee.token })
			.then((response) => {

				commit('agentFee', response.data)
				commit('loading', false)
			}).catch(_ => {
				commit('loading', false)
			})
	},
	async marketingPrice({ commit, state }, params) {
		commit('loading', true)
		commit('setStatsCancelToken', { marketingPrice: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/marketing-price`, { params, cancelToken: state.statsCancelToken.marketingPrice.token })
			.then((response) => {
				commit('marketingPrice', response.data)
				commit('loading', false)
			}).catch(_ => {
				commit('loading', false)
			})
	},

	async manualAdjustmentPositive({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			manual_adjustment_positive: true,
		})
		commit('setStatsCancelToken', { manual_adjustment_positive: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/manual-adjustment-positive`, { params, cancelToken: state.statsCancelToken.manual_adjustment_positive.token })
			.then((response) => {
				commit('setSummary', {
					manual_adjustment_positive: response.data
				})
				commit('setAllSummaryLoading', {
					manual_adjustment_positive: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					manual_adjustment_positive: false,
				})
			})
	},
	async manualAdjustmentNegative({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			manual_adjustment_negative: true,
		})
		commit('setStatsCancelToken', { manual_adjustment_negative: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/manual-adjustment-negative`, { params, cancelToken: state.statsCancelToken.manual_adjustment_negative.token })
			.then((response) => {
				commit('setSummary', {
					manual_adjustment_negative: response.data
				})
				commit('setAllSummaryLoading', {
					manual_adjustment_negative: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					manual_adjustment_negative: false,
				})
			})
	},
	async topFiveDirectPlayersDeposit({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			top_five_direct_players_deposit: true,
		})
		commit('setStatsCancelToken', { top_five_direct_players_deposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/top-five-direct-players-deposit`, { params, cancelToken: state.statsCancelToken.top_five_direct_players_deposit.token })
			.then((response) => {
				commit('setSummary', {
					top_five_direct_players_deposit: response.data
				})
				commit('setAllSummaryLoading', {
					top_five_direct_players_deposit: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					top_five_direct_players_deposit: false,
				})
			})
	},
	async topFiveAssociatePlayersDeposit({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			top_five_associate_players_deposit: true,
		})
		commit('setStatsCancelToken', { top_five_associate_players_deposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/top-five-associate-players-deposit`, { params, cancelToken: state.statsCancelToken.top_five_associate_players_deposit.token })
			.then((response) => {
				commit('setSummary', {
					top_five_associate_players_deposit: response.data
				})
				commit('setAllSummaryLoading', {
					top_five_associate_players_deposit: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					top_five_associate_players_deposit: false,
				})
			})
	},
	async activePlayer({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			activePlayer: true,
		})
		commit('setStatsCancelToken', { activePlayer: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/count-active-player`, { params, cancelToken: state.statsCancelToken.activePlayer.token })
			.then((response) => {
				commit('setSummary', {
					activePlayer: response.data
				})
				commit('setAllSummaryLoading', {
					activePlayer: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					activePlayer: false,
				})
			})
	},
	async bonus({ commit, state }, params) {
		commit('setAllSummaryLoading', {
			bonus: true,
		})
		commit('setStatsCancelToken', { bonus: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/summary/bonus`, { params, cancelToken: state.statsCancelToken.bonus.token })
			.then((response) => {
				commit('setSummary', {
					bonus: response.data
				})
				commit('setAllSummaryLoading', {
					bonus: false,
				})
			}).catch(_ => {
				commit('setAllSummaryLoading', {
					bonus: false,
				})
			})
	},

	async highestDeposit({ commit, state }, params) {
		commit('setStatsCancelToken', { highestDeposit: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/summary/highest-deposit-players`, {
				params,
				cancelToken: state.statsCancelToken.highestDeposit.token
			})
			.then((response) => {
				commit('setHighestDeposit', response.data)
				commit('loading', false)
			})
	},
	async highestWithdrawal({ commit, state }, params) {
		commit('setStatsCancelToken', { highestWithdrawal: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/summary/highest-withdrawal-players`, {
				params,
				cancelToken: state.statsCancelToken.highestWithdrawal.token
			})
			.then((response) => {
				commit('setHighestWithdrawal', response.data)
				commit('loading', false)
			})
	},
	async getPlayerWinLose({ commit, state }, param) {
		const params = {
			type: param.type,
			...param
		}
		if (param.type === 'win') {
			commit('setPlayerWinListLoading', true)
			commit('setStatsCancelToken', { playerWinning: this.$axios.CancelToken.source() })

		}
		if (param.type === 'lose') {
			commit('setPlayerLostListLoading', true)
			commit('setStatsCancelToken', { playerLosing: this.$axios.CancelToken.source() })
		}

		await this.$axios
			.get(`/statistics/all-summary/player-win-lose`, {
				params,
				cancelToken: param.type === 'win' ? state.statsCancelToken.playerWinning.token : state.statsCancelToken.playerLosing.token
			})
			.then((response) => {
				if (params.type === 'lose') {
					commit('setPlayerLostList', response.data)
				} else {
					commit('setPlayerWinList', response.data)
				}
			})
		if (param.type === 'win') commit('setPlayerWinListLoading', false)
		if (param.type === 'lose') commit('setPlayerLostListLoading', false)
	}
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	stats: (state, v) => {
		state.stats = v
	},
	setSummary: (state, v) => {
		state.allSummary = { ...state.allSummary, ...v }
	},
	depositPlayerList: (state, v) => {
		state.depositPlayerList = v
	},
	withdrawalPlayerList: (state, v) => {
		state.withdrawalPlayerList = v
	},
	agentFee: (state, v) => {
		state.agentFee = v
	},
	marketingPrice: (state, v) => {
		state.marketingPrice = v
	},
	setHighestDeposit: (state, v) => {
		state.highestDeposit = v
	},
	setHighestWithdrawal: (state, v) => {
		state.highestWithdrawal = v
	},
	setPlayerWinListLoading: (state, v) => {
		state.playerWinListLoading = v
	},
	setPlayerLostListLoading: (state, v) => {
		state.playerLoseListLoading = v
	},
	setPlayerWinList: (state, v) => {
		if (!v.data) return null;

		let defaultArray = []
		if (v.data.length === 0) { v.total = 1 }
		if (v.data.length < 10) {
			defaultArray = Array.from({ length: 10 - v.data.length }, () => ({ player_id: '-', created_at: '-', ggr: '-', total_net_cashflow: '-', total_turnover: '-' }))
			v.data = v.data.concat(defaultArray)
		}
		v.total = ~~v.total

		state.playerWinList = v
	},
	setPlayerLostList: (state, v) => {
		if (!v.data) return null;

		let defaultArray = []
		if (v.data.length === 0) { v.total = 1 }
		if (v.data.length < 10) {
			defaultArray = Array.from({ length: 10 - v.data.length }, () => ({ player_id: '-', created_at: '-', ggr: '-', total_net_cashflow: '-', total_turnover: '-' }))

			v.data = v.data.concat(defaultArray)
		}
		v.total = ~~v.total
		state.playerLostList = v
	},
	setAllSummaryLoading: (state, v) => {
		state.allSummaryLoading = { ...state.allSummaryLoading, ...v }
	},
	setStatsCancelToken: (state, data) => {
		const oldData = { ...state.statsCancelToken }
		state.statsCancelToken = {
			...oldData, ...data
		}
	}
}