
export default {
    name: 'Blank',
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: '/csl.png',
                },
            ],
        }
    },
}
