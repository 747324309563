
export default {
    name: 'MonthPicker',
    props: {
        label: {
            type: String,
            default: '',
        },
        monthFormat: {
            type: String,
            default: 'MM-YYYY',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: [Boolean, String],
            default: false,
        },
        hideDetails: {
            type: [Boolean, String],
            default: null,
        },
        light: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: null, // value format should be YYYY-MM only
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputId: {
            type: String,
            default: 'start-month',
        },
    },
    data: () => ({
        date: null, // Return v-model value format is YYYY-MM only
        inputDate: '', // Show value format in text-field depend on monthFormat
        menu: false,
        modal: false,
    }),
    watch: {
        value(val) {
            if (!val) return
            this.inputDate = this.$dayjs(val).format(this.monthFormat)
        },
    },
    mounted() {
        if (this.value) {
            this.inputDate = this.$dayjs(this.value).format(this.monthFormat)
            if (!this.inputDate.includes('Invalid')) return
            this.inputDate = ''
            this.$emit('input', null)
        }
    },
    methods: {
        selectedMonth() {
            if (this.date) {
                this.$refs.mdialog.save(this.date)
                this.inputDate = this.$dayjs(this.date).format(this.monthFormat)
                this.$emit('input', this.date)
            }
        },
    },
}
