
export default {
    name: 'FilterForm',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        allowRefreshEvt: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        doRefresh() {
            if (this.allowRefreshEvt) {
                this.$emit('refresh', true)
            } else {
                this.$router.go(0)
            }
        },
        onSearch() {
            this.$parent.$parent.API()
            this.$emit('search')
        },
    },
}
